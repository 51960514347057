var presentAfterpay = presentAfterpay || {};

(function ($) {
  $(document).on('change', '.js-product-qty-select', function () {
    var $product = $(this).closest('.js-product');
    var $addToBag = $('.js-add-to-cart', $product);
    var newQty = $(this).val() - 0;

    $addToBag.data('qty', newQty);
    $product.trigger('product.qtySelect', [newQty]);
    if (Drupal.settings.common.clearpay_price) {
      Drupal.behaviors.productInstallmentPriceUKV1.initClearPay();
    } else {
      afterPayMessage();
    }
  });
  function afterPayMessage() {
    if (!$('.js-afterpay').length) {
      return;
    }
    var amount = null;

    if ($('.js-price-for-installment').length) {
      amount = $('.js-price-for-installment').has('.on-sale').length
        ? $('.on-sale').html().trim().split('$')[1].replace('.', '')
        : $('.js-price-for-installment').html().trim().split('$')[1].replace('.', '');
    } else if ($('.js-selectBox-label').length) {
      amount = $('.js-selectBox-label option:selected').text().trim().split('$')[1].replace('.', '');
    }
    if (amount !== null && typeof presentAfterpay.afterpayConfig === 'function') {
      presentAfterpay.afterpayConfig(amount);
    }
  }
})(jQuery);
